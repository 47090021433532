import { get } from "oj-ajax"
import { getRootElements } from "oj-dom-utils"
import { debounce } from "oj-utils"
import m from "mithril"

export const isTouchDevice = () =>
    "ontouchstart" in window


export const getFileExtensionFromString = (str: string) =>
    str.split(".")[1]

export const mounter = <T extends {}>(selector: string, cb: (root: HTMLElement, data: T | null) => void) =>
    getRootElements(selector)
        .forEach(x => {
            const inputs = Array.from(x.querySelectorAll("input"))
            let data: any = null
            if (inputs.length > 1)
                data = inputs.map(x => {
                    try {
                        return JSON.parse(x.value)
                    } catch (err) {
                        return x.value
                    }
                })
            else if (inputs[0] instanceof HTMLInputElement)
                try {
                    data = JSON.parse(inputs[0].value)
                } catch (err) {
                    data = inputs[0].value
                }
            cb(x, data)
        })

export const downloadFile = (response: any, name: string) => {
    if (navigator.appVersion.toString().indexOf('.NET') > 0)
        window.navigator["msSaveBlob"](response, name)
    else {
        const blob = new Blob([response], { type: response.type })
        const objectUrl = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        document.body.appendChild(a)
        a.href = objectUrl
        a.download = name
        a.click()
        URL.revokeObjectURL(objectUrl)
    }
}

export const testMailAddress = (mail: string) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)

export const loadScript = (src: string) =>
    new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.onload = resolve
        script.onerror = reject
        script.src = src
        document.body.appendChild(script)
    })

export const loadScriptSource = (txt: string) =>
    new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.onload = resolve
        script.onerror = reject
        script.innerHTML = txt
        document.body.appendChild(script)
    })

export const hideScrollbars = (hide: boolean) => {
    document.querySelector<HTMLBodyElement>("body").classList.toggle("hide-scrollbars", hide);
}

export const formatPrice = (price: number, currency = true) => {
    const formatedPrice = new Intl.NumberFormat(window.culture, {
        style: 'currency',
        currency: 'EUR',
    }).format(price);

    if (currency)
        return formatedPrice.replace(",", ".")
    else
        return formatedPrice.replace(",", ".").replace("€", "").trim()
}

export const formatHtmlPrice = (price: number) => {
    const formatNumber = new Intl.NumberFormat(window["culture"], { minimumFractionDigits: 2 }).format(
        price,
    )
    const priceParts = formatNumber.toString().replace(".", "").split(",");

    if (priceParts[1] != "00") {
        return `<span>${priceParts[0]}</span>.<sup>${priceParts[1]}</sup>`
    } else {
        return `<span>${priceParts[0]}</span><span class="price-round"></span>`
    }
}

interface ImageDimensions {
    width: number;
    height: number;
}

export const getImageDimensions = (imageUrl): Promise<ImageDimensions> => {
    return new Promise((resolve, reject) => {
        const img = new Image();  // Create a new image object

        img.onload = function () {
            // Once the image is loaded, resolve the promise with the width and height
            resolve({ width: img.width, height: img.height });
            img.onload = null;
            img.src = '';
        };

        img.onerror = function () {
            // Reject the promise if there is an error loading the image
            reject(new Error("Error loading image."));
        };

        // Set the image source to the URL, which triggers the loading process
        img.src = imageUrl;
    });
}

export const getAddress = debounce(500, async (form: any) => {
    const postalCode = form.getFields().postalCode.value
    const houseNumber = form.getFields().houseNumber.value

    if (postalCode && houseNumber) {
        await get<string>(`/${window["culture"]}/Checkout/FindAddress/?PostalCode=${postalCode}&HouseNumber=${houseNumber}`)
            .then(res => JSON.parse(res))
            .then(res => {
                if (res.street && res.city) {
                    form.getFields().street.value = res.street
                    form.getFields().city.value = res.city
                }
                form.getFields().street.validate.readonly = false
                form.getFields().city.validate.readonly = false
                m.redraw()
            })
    }
})
const NL = {
    none: '- Geen -',
    optional: '(optioneel)',
    fileName: 'Bestandsnaam',
    add: "Toevoegen",
    headerYourInfo: 'jouw gegevens',
    headerYourQuestion: 'jouw vraag',
    send: 'Verzenden',
    makeYourChoice: "maak je keuze",
    fieldSalutationLabel: 'Aanhef',
    fieldSalutationMessage: 'Aanhef is verplicht',
    fieldNameLabel: 'Naam',
    fieldNameMessage: 'Naam is verplicht',
    fieldEmailAddressLabel: 'e-mailadres',
    fieldEmailMessage: 'E-mail is verplicht',
    fieldTelephoneLabel: 'Telefoon',
    fieldTelephoneMessage: 'Telefoon is verplicht',
    fieldOrderNumberLabel: 'Ordernummer',
    fieldOrderNumberMessage: 'Ordernummer is verplicht',
    fieldPlatformLabel: 'Mijn vraag gaat over',
    fieldPlatformMessage: 'Maak een keuze',
    fieldSubjectLabel: 'Onderwerp',
    fieldSubjectMessage: 'Onderwerp is verplicht',
    fieldDescriptionLabel: 'Vraag, opmerking of suggestie',
    fieldDescriptionMessage: 'Dit veld is verplicht',
    optionPlatform1: 'HEMA Foto',
    optionPlatform2: 'HEMA',
    optionSalutation1: 'Neutraal',
    optionSalutation2: 'Meneer',
    optionSalutation3: 'Mevrouw',
    successTitle: 'je bericht is verzonden',
    successDescription: 'Wij proberen je bericht zo snel mogelijk te beantwoorden.',
    newsletterTitle: "blijft altijd op de hoogte van onze acties",
    newsletterDescription: "Schrijf je in voor de HEMA nieuwsbrief en mis nooit meer iets.",
    newsletterSubscribe: "meld je aan",
    newsletterSignIn: "schrijf je in",
    newsletterSuccess: "Gelukt! Je ontvangt vanaf nu onze nieuwsbrief.",
    invalidEmail: "Dit is geen geldig e-mailadres",
    seeAll: "bekijk alles"
}

const FR: typeof NL = {
    none: '-- Aucun --',
    optional: '(optionnel)',
    fileName: 'Nom de fichier',
    add: "Ajouter",
    headerYourInfo: 'vos coordonnées',
    headerYourQuestion: 'votre question',
    send: 'Expédier',
    makeYourChoice: "faites votre choix",
    fieldSalutationLabel: 'Titre',
    fieldSalutationMessage: 'Entrez votre titre',
    fieldNameLabel: 'Nom',
    fieldNameMessage: 'Entrez votre nom',
    fieldEmailAddressLabel: 'e-mailadres',
    fieldEmailMessage: 'Entrez votre e-mail',
    fieldTelephoneLabel: 'Téléphone',
    fieldTelephoneMessage: 'Entrez votre téléphone',
    fieldOrderNumberLabel: 'Numéro de commande',
    fieldOrderNumberMessage: 'Entrez votre numéro de commande',
    fieldPlatformLabel: 'Ma question concerne',
    fieldPlatformMessage: 'Ma question concerne',
    fieldSubjectLabel: 'Sujet',
    fieldSubjectMessage: 'Entrez votre sujet',
    fieldDescriptionLabel: 'Question, remarque ou suggestion',
    fieldDescriptionMessage: 'Entrez votre question, remarque ou suggestion',
    optionPlatform1: 'HEMA Photo',
    optionPlatform2: 'HEMA',
    optionSalutation1: 'Neutre',
    optionSalutation2: 'Monsieur',
    optionSalutation3: 'Madame',
    successTitle: 'votre message a été envoyé',
    successDescription: 'Nous essayons de répondre à votre message dès que possible.',
    newsletterTitle: "restez toujours informé de nos promotions",
    newsletterDescription: "Inscrivez-vous à la newsletter HEMA et ne manquez plus rien.",
    newsletterSubscribe: "inscris-toi",
    newsletterSignIn: "inscrivez-vous",
    newsletterSuccess: "Réussi ! Vous recevrez désormais notre newsletter.",
    invalidEmail: "Ce n'est pas une adresse e-mail valide",
    seeAll: "voir tout"
}



const translations: Record<Window['culture'], typeof NL> = {
    NL,
    FR
}

export const getTranslation = <T extends LanguageKey>(key: T): typeof NL[T] => {
    if (window.culture === "fr-BE")
        return translations["FR"][key]

    return translations["NL"][key]
}

export type LanguageKey = keyof typeof NL

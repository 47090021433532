import m, { Vnode } from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { formatHtmlPrice, formatPrice, mounter } from "../utils"
import { debounce } from "oj-utils"
import { Carousel, CarouselItem } from "../components/Carousel/Carousel"
import { IItem } from "./trackingTypes"
import { redrawOnBreakpoint } from "../mobile"
import { Svg } from "../svg"
import { getTranslation } from "../translation"
import { type IPictureElement, PictureElement } from "../components/images/picture"

interface IOverview {
    title: string,
    subTitle: string,
    navigateUrl: string,
    items: (IHotspotItem | IContentItem)[],
    isCarousel?: boolean
}

interface IHotspotItem {
    navigateUrl: string,
    openInNewTab: boolean,
    title: string,
    labels: ILabel[]
    images: IPictureElement,
    hoverImages: IPictureElement,
    subtitle: string,
    actionPrice: number,
    price: number,
    priceText: string,
    i?: number
    list?: string
    productId?: string
    editorType?: string
    category?: string
    fujiId?: string
    campaignLabel?: string
    regularPrice?: string
    discountPrice?: string
    type?: HotspotType
}

interface ILabel {
    color: HotspotColor,
    colorHex: string,
    label: string
}

interface IContentItem {
    title: string,
    images: IPictureElement,
    linkUrl: string,
    openInNewTab: boolean,
    linkText: string,
    color: string,
    type?: HotspotType
}

enum HotspotType {
    Product = 0,
    Content = 1,
}

enum HotspotColor {
    Red = 0,
    Blue = 1,
    Purple = 2,
    Yellow = 3
}

const isMobile = redrawOnBreakpoint(830)

export class HotspotGrid extends MithrilTsxComponent<IOverview> {
    oninit(v: Vnode<IOverview>) {
        window.addEventListener('resize', debounce(1000, () => {
            m.redraw()
        }))
    }

    view(v: Vnode<IOverview>) {
        const overview: IOverview = v.attrs

        if (overview.items.length === 0)
            return

        return [
            (v.attrs.title || v.attrs.subTitle) && <header className="hotspot-heading heading">
                <div>
                    {v.attrs.title && <h2 className="title h2">{overview.title}</h2>}
                    {v.attrs.subTitle && <p className="description">{v.attrs.subTitle}</p>}
                </div>
                {(v.attrs.isCarousel && v.attrs.navigateUrl) && <a className="link" href={v.attrs.navigateUrl} target="_self" aria-label={getTranslation("seeAll")}>
                    {!isMobile() && <span>{getTranslation("seeAll")}</span>}
                    <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/arrow-right.svg" /></i></a>}
            </header>,
            v.attrs.isCarousel
                ? <div className="hotspot-slider" style={`--viewport-width: ${document.documentElement.clientWidth}px`}><Carousel scrollVisibleSlides={true} disableSwipe={(!isMobile() && overview.items.length <= 5)} navigation emblaOptions={{
                    slidesToScroll: 1,
                    align: "start",
                    inViewThreshold: 1,
                    speed: 13
                }}
                    onload={(data) => {
                        data.embla.reInit({ slidesToScroll: data.embla.slidesInView().length })

                        data.embla.on("resize", debounce(1000, () => {
                            data.embla.reInit({ slidesToScroll: data.embla.slidesInView().length })
                        }))
                    }}
                >{[...overview.items].filter(item => item.type === HotspotType.Product).map((x, i) =>
                    <CarouselItem>
                        <HotspotItem {...x as IHotspotItem} i={i} list={overview.title} />
                    </CarouselItem>

                )}
                    {v.attrs.navigateUrl && <CarouselItem>
                        <a className="see-all-item" href={v.attrs.navigateUrl} target="_self">
                            <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/arrow-right.svg" /></i>
                            <span>{getTranslation("seeAll")}</span>
                        </a>
                    </CarouselItem>}
                </Carousel></div>
                : <div className="hotspot-overview">
                    {overview.items.map((x, i) => {
                        if (x.type === HotspotType.Product)
                            return <HotspotItem {...x as IHotspotItem} i={i} list={overview.title} />
                        else
                            return <ContentItem {...x as IContentItem} />
                    }
                    )}
                </div>
        ]
    }
}

interface ItemList extends IItem {
    item_list_name: string,
    index: string
}

export class HotspotItem extends MithrilTsxComponent<IHotspotItem> {
    price
    item: IItem
    itemList: ItemList

    oninit(v: Vnode<IHotspotItem>) {
        this.price = v.attrs.actionPrice || v.attrs.price
        this.formatGtmData(v)
    }

    view(v: Vnode<IHotspotItem>) {
        const hotspotItem: IHotspotItem = v.attrs;

        return <div className="hotspot-item"
            data-onviewport={`window.oj.impressionItem("view_item", { items: [${JSON.stringify({ ...this.itemList })}] }, "${this.itemList.item_list_name}")`}
        >
            <a
                className="image"
                href={hotspotItem.navigateUrl}
                title={hotspotItem.title}
                target={hotspotItem.openInNewTab ? "_blank" : "_self"}
                rel={hotspotItem.openInNewTab ? "noreferrer noopener" : ""}
                onclick={() => {
                    window.ojtag("select_item", { items: [{ ...this.itemList }] })

                    if (hotspotItem.campaignLabel)
                        window.ojtag("select_promotion", { items: [this.item], promo: [{ name: hotspotItem.campaignLabel }] })
                }}
            >
                {hotspotItem.images && <PictureElement {...hotspotItem.images} />}
                {hotspotItem.hoverImages && <PictureElement {...hotspotItem.hoverImages} className="hover" />}

                {hotspotItem.labels?.length > 0 && <div className="signings">
                    {/* <span className="signing" style={`--bgcolor:${hotspotItem.labels[0].colorHex};`}>xd</span> */}
                    {hotspotItem.labels.map(x =>
                        <span className="signing" style={`--bgcolor:${x.colorHex};`}>
                            {x.label}
                        </span>
                    )}
                </div>}
            </a>

            <a
                className="info"
                href={hotspotItem.navigateUrl}
                title={hotspotItem.title}
                target={hotspotItem.openInNewTab ? "_blank" : "_self"}
                rel={hotspotItem.openInNewTab ? "noreferrer noopener" : ""}
                onclick={() => {
                    window.ojtag("select_item", { items: [{ ...this.itemList }] })

                    if (hotspotItem.campaignLabel)
                        window.ojtag("select_promotion", { items: [this.item], promo: [{ name: hotspotItem.campaignLabel }] })
                }}
            >
                <span className="title">{hotspotItem.title}</span>
                <span className="subtitle">{hotspotItem.subtitle}</span>
            </a>

            {this.price && <div className="pricing">
                {hotspotItem.actionPrice && <span className="action">{m.trust(formatPrice(hotspotItem.price, false))}</span>}
                {hotspotItem.priceText && <span className="label">{hotspotItem.priceText}</span>}
                <span className="price">{m.trust(formatHtmlPrice(this.price))}</span>
            </div>}
        </div>
    }

    formatGtmData(v: Vnode<IHotspotItem>) {
        const hotspotItem: IHotspotItem = v.attrs

        const cats = hotspotItem.category?.split("/").filter(x => x && x !== " ") ?? [""]
        const listName = hotspotItem.list ?? v.attrs.list;

        this.item = {
            item_id: hotspotItem.fujiId ?? hotspotItem.productId,
            item_name: hotspotItem.title,
            item_category: cats.length > 0 ? cats[0] : null,
            item_category2: cats.length > 1 ? cats[1] : null,
            item_category3: cats.length > 2 ? cats[2] : null,
            price: hotspotItem.regularPrice || null,
            discount: hotspotItem.discountPrice || null,
            editor_type: hotspotItem.editorType,
        }

        const list = {
            item_list_name: listName,
            index: (hotspotItem.i + 1).toString()
        }

        this.itemList = { ...this.item, ...list }
    }
}


class ContentItem extends MithrilTsxComponent<IContentItem> {
    view(v: m.Vnode<IContentItem>) {
        const { title, linkUrl: navigateUrl, openInNewTab, linkText, images, color } = v.attrs;

        return <a className="content-item" style={`--bg-color: ${color}`} href={navigateUrl} target={openInNewTab ? "_blank" : "_self"} rel={openInNewTab ? "noreferrer noopener" : ""} title={title}>
            <span className="title h3">{title}</span>
            <span className="visual-link">{linkText}</span>
            <PictureElement {...images} />
        </a>
    }
}

mounter<IOverview>(`[data-m-hotspot-slider]`, (r, d) => {
    m.mount(r, {
        view: v => <HotspotGrid {...d} />
    })
})
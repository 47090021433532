import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"

export interface ISvg {
  src: string
  onload?: (svg: SVGElement) => any
}

export class Svg extends MithrilTsxComponent<ISvg> {
  svg: string
  loaded: boolean

  view(v: m.Vnode<ISvg>) {
    if (!this.svg)
      return

    return m.trust(this.svg)
  }

  oninit(v: m.Vnode<ISvg>) {
    this.load(v)
  }

  onbeforeupdate(v: m.Vnode<ISvg>, o: m.Vnode<ISvg>) {
    if (v.attrs.src !== o.attrs.src)
      this.load(v)
  }

  onupdate(v: m.VnodeDOM<ISvg>) {
    if (!this.svg || this.loaded)
      return

    if (this.svg) {
      this.loaded = true
      v.attrs.onload?.(v.dom as SVGElement)
      v.dom.setAttribute('aria-hidden', 'true')
      v.dom.setAttribute('focusable', 'false')
    }
  }

  async load(v: m.Vnode<ISvg>) {
    if (!v.attrs.src)
      return

    this.loaded = false
    this.svg = await fetch(v.attrs.src).then(x => x.text())
    m.redraw()
  }
}

import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { cleanString } from "oj-utils"
import EmblaCarousel, { EmblaCarouselType, EmblaOptionsType } from "embla-carousel"
import { Svg } from "../../svg"

export interface ICarousel {
    className?: string,
    navigation?: boolean,
    emblaOptions?: Partial<EmblaOptionsType>,
    disableSwipe?: boolean,
    scrollVisibleSlides?: boolean
    onload?: (data: { dom: HTMLElement, embla: EmblaCarouselType }) => void
}

export class Carousel extends MithrilTsxComponent<ICarousel> {
    embla: EmblaCarouselType
    slidesInView: number

    view(v: m.Vnode<ICarousel>) {
        const canScrollPrev = v.attrs.navigation && this.embla && this.embla.canScrollPrev()
        const canScrollNext = v.attrs.navigation && this.embla && this.embla.canScrollNext()

        return <div className="emblaCarousel">
            <div className={cleanString("embla", v.attrs.className, canScrollPrev && "has-prev", canScrollNext && "has-next")}>
                <div className="embla__container">
                    {v.children}
                </div>
            </div>
            {v.attrs.navigation && this.embla &&
                <CarouselNav
                    canScrollPrev={canScrollPrev}
                    canScrollNext={canScrollNext}
                    scrollPrev={this.embla.scrollPrev}
                    scrollNext={this.embla.scrollNext}
                />
            }
        </div>
    }

    oncreate(v: m.VnodeDOM<ICarousel>) {
        const emblaNode = document.querySelector('.emblaCarousel .embla') as HTMLDivElement;


        this.embla = EmblaCarousel(emblaNode, {
            align: "center",
            containScroll: "trimSnaps",
            draggable: !v.attrs.disableSwipe,
            ...v.attrs.emblaOptions ?? {},
        })

        this.embla.on("init", () => setTimeout(m.redraw, 80))
        this.embla.on("select", () => setTimeout(m.redraw, 80))
        this.embla.on("resize", () => setTimeout(m.redraw, 80))

        v.attrs.onload?.({ dom: emblaNode, embla: this.embla });
        [1000, 2000].forEach(x => setTimeout(m.redraw, x))
    }

    onbeforeupdate(v: m.VnodeDOM<ICarousel>, o: m.VnodeDOM<ICarousel>) {
        if (v.attrs.disableSwipe !== o.attrs.disableSwipe)
            this.embla.reInit({ draggable: !v.attrs.disableSwipe })
    }
}

export interface ICarouselNav {
    canScrollPrev: boolean,
    scrollPrev: Function,
    canScrollNext: boolean,
    scrollNext: Function,
}

export class CarouselNav extends MithrilTsxComponent<ICarouselNav> {
    view(v: m.Vnode<ICarouselNav>) {
        return [
            <button aria-label="slide previous" className={cleanString("embla-arrow embla-arrow-prev nav-gallery")} disabled={!v.attrs.canScrollPrev} onclick={v.attrs.scrollPrev}>
                <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/arrow-left.svg" /></i>
            </button>,
            <button aria-label="slide next" className={cleanString("embla-arrow embla-arrow-next nav-gallery")} disabled={!v.attrs.canScrollNext} onclick={v.attrs.scrollNext}>
                <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/arrow-right.svg" /></i>
            </button>
        ]
    }
}

export interface ICarouselItem {
    className?: string,
}

export class CarouselItem extends MithrilTsxComponent<ICarouselItem> {
    view(v: m.Vnode<ICarouselItem>) {
        return <div className={cleanString("CarouselItem embla__slide", v.attrs.className)}>
            {v.children}
        </div>
    }
}

export interface ICarouselImageItem extends ICarouselItem {
    eager?: boolean
    src: string
}

export class CarouselImageItem extends MithrilTsxComponent<ICarouselImageItem> {
    view(v: m.Vnode<ICarouselImageItem>) {
        return <CarouselItem {...v.attrs}>
            <img className="embla__slide__img" loading={v.attrs.eager ? "eager" : "lazy"} src={v.attrs.src} />
        </CarouselItem>
    }
}
import m from "mithril"
import { memoize } from "oj-utils"

export const redrawOnBreakpoint = memoize((width: number = 768) => {
    const mediaQueryList = window.matchMedia(`(min-width: ${width}px)`)
    let matches = mediaQueryList.matches

    mediaQueryList.onchange = (event) => {
        m.redraw()
        matches = event.matches
    }
    return () => !matches
})

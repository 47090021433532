import m, { Vnode, VnodeDOM } from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { type IImage } from "../../types"
import { cleanString } from "oj-utils"

export interface IPictureElement {
    className?: string,
    items: IImage[]
}
export class PictureElement extends MithrilTsxComponent<IPictureElement> {
    
    view(v: Vnode<IPictureElement>) {
        return <picture className={cleanString(v.attrs.className)}>
            {v.attrs.items.map((x, i) => {
                if (v.attrs.items.length - 1 === i)
                    return <img width={v.attrs.items[0].width} height={v.attrs.items[0].height} src={x.imageUrl} srcset={`${x.imageUrl} 1x, ${x.retinaUrl} 2x`} loading="lazy" alt={x.altText} />
                else
                    return <source srcset={`${x.imageUrl} 1x, ${x.retinaUrl} 2x`} type="image/webp" media={x.minWidth} />
            })}
        </picture>
    }
}